import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHero from "../../components/pagehero/PageHero";
import RenderValuableInformation from "../../components/renderrecipe/RenderValuableInformation";
import { API_BASE_URL } from '../../config';
import './FullRecipe.css';

const FullRecipe = () => {
  const { slug } = useParams(); // Get the slug from URL parameters
  const [recipe, setRecipe] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(''); // State to hold the background image URL

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`); // API call with slug
        const data = await response.json();
        setRecipe(data);
        
        // Set background image after fetching the recipe data
        if (data.image) {
          setBackgroundImage(`${API_BASE_URL}/recipes/${data.image}.webp`); // Dynamically set background image
        }
      } catch (error) {
        console.error("Error fetching recipe:", error);
      }
    };

    fetchRecipe();
  }, [slug]);

  if (!recipe) {
    return <p>Loading recipe...</p>;
  }
  return (
    <div className="full-recipe-container">
      {/* Pass the background image as an inline style to PageHero */}
      <PageHero 
        title={recipe.title}
        image={ backgroundImage} // Set the background image
      />
      <div className="recipe-info-container">
        <RenderValuableInformation 
          valuableInfo={recipe.valuable_info}
        />
        <p className="description-of-recipe">{recipe.description}</p>
        <div className="steps-ingredients-container">
        <div className="ingredients-container">
          <h2>Ingredients</h2>
          <ul className="ingredients">
            {
              recipe.ingredients.map((ingredient, index) => (
                <li key={index} className="ingredient-item">
                  <strong>{ingredient.quantity_unit} </strong>{ingredient.name}
                </li>
              ))
            }
          </ul>
        </div>
          <div className="instructions-container">
            <h2>Instructions</h2>
            <ul className="instructions">
            {
              recipe.instructions.map((instruction, index) => (
                <li key={index} className="instruction-item">
                  <strong>Step {instruction.step_number} &#8658; </strong> {instruction.step}
                </li>
              ))
            }
          </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullRecipe;
